.digital-background{
    margin-bottom: -370px;
    min-height: 97%;
}
.digital-content{
    padding-top: 140px; 
    width: 100vw;
    min-height: 50vh;
}
.push {
    height: 366px;
  }
.digital-box{
    margin: 0 40px ;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 30px;
    align-items: flex-start;
    
}
.digital-title{
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 16px
}
.digital-column{
    grid-column: 2/6;
    padding-bottom: 24px;
}
.digital-subtitle{
    font-size: 1em;
    margin-bottom: 10px;
}
.digital-text{
    color: #1e0606;
    margin-bottom: 16px;
}
.digital-video{
    width: 80%;
    height: 100%;
    margin-bottom: 16px;
   margin-left: 60px;
   border: 2px solid #9b2743;
}
.vimeo-box{
    margin-top: 16px;
    width: 80%;
}
.two-column{
    margin-top: 16px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 30px;
    align-items: flex-start;
}

.caracter{
    width: 30px;
    height: 30px;
    background-color: #9b2743;
    color:#fff;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 3px 0 0;

}
.digital-item{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.digital-grey{
    background-color: #B7B6B6;
    margin-top: 16px;
    color: #fff;
    padding: 7px;
    font-weight: 600;
    width: 100%;
}
.digital-grey p{
    font-weight: 600;
}
.digital-links{
    margin-left:33px;
}
.digital-links a{
    color: #9b2743;
}
.second{
    padding-top: 13px;
    grid-column: 2;
}
.digital-foot{
    margin-top: 16px;
    color: #1e0606;
}
.digital-block{
    margin-top: 16px;
}
.final-list{
    font-size: .8em;
}
.player-wrapper {
    width: auto; 
    height: auto; 
  }
/* .video-size{
    height: 80%;
    top: 0;
    left: 0;

} */

@media only screen and (min-width: 1440px){
    

}
@media only screen and (min-width: 1023px) and (max-width: 1261px){
   .second{
    grid-column: 1/3;
   }
   .first{
    grid-column: 1/3;
   }
   .digital-box{
    padding-bottom: 5%;
   }

}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    
    .digital-box{
        padding-bottom: 5%;
       }
       .digital-title{
        font-size: 18px;
       }
       .second{
        grid-column: 1/3;
       }
       .first{
        grid-column: 1/3;
       }
       .digital-column{
        grid-column: 2/7;
       }
       .vimeo-box{
        position: relative;   
       }
       .video-size{
        position: absolute;
        top: 0;
        left: 0;

       }
      
      
} 