.rent-backgound{
    background: linear-gradient(270deg, rgba(0, 0, 0, 0) 19.6%, rgba(0, 0, 0, 0.5) 100%),  url(../../../public/img/AlquilerLlaves.jpg);
    background-size: cover ;
    height: 105vh;
    width: 100vw;

}
.rent-hero{
    width: 100vw;
    box-sizing: border-box;
    position: absolute;
    margin-top: 3%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
   column-gap: 30px;
   row-gap: 16px;
    padding: 20px 40px 0 40px; 
}
.rent-block{
    grid-column: 2/5;
    padding-top: 10%;
}
.rent-sub-menu{
    display: flex;
   justify-content: space-between;
   border-bottom: 1px solid #FFF;
   padding-bottom: 5px;

}
.item-rent-selected{
    margin: 0;
    font-family: 'Sora', sans-serif;
    font-size: 15px;
    color: #FFF;
    font-weight: 800;
    border-right: 1.5px solid #D8CECE;
    padding-right: 8px;
    margin-right: 8px;
}
.item-rent{
    font-size: 15px;
    color: #FFF;
    font-weight: 400;
    padding-right: 8px;
    margin-right: 8px;
}
.rent-home-link h5{
    color: #fff;
    font-size: 14px;
}
.rent-title{
    margin-top: 8px;
    color: #fff;
    font-size: 1em;
    text-transform: uppercase;
}
.rent-title p{
    margin-top: 8px;
    color: #fff;
    font-size: 1em;
    text-transform:none;
}
.rent-form{
    grid-column: 2/4;

}
.rent-label{
    color: #fff;
    font-weight: 600;
    margin: 5px 10px 3px 0;
    font-size: .7em;
}
.radio-box{
    display: flex;
    justify-content: left ;
    align-items: center; 
}

.rent-send{
    text-align: right;
    margin-top: 30px;
}
.rent-result{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 36px;
    padding-left: 15px;
    background-color: rgba(255, 255, 255, .4);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 1.7em;
    color: #fff;
}
.rent-result-box{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;

}
.i{
    position: absolute;
    font-family: 'Sora', sans-serif;
    font-size: 1.7em;
    font-weight: 600;
    color:#fff;
    padding: 1px 10px 0 10px;
    font-style: normal;
}
.input-explain{
    margin-bottom: 20px;
}
.legal-bond{
    font-size: 12px;
    color: #FFF;
    margin-top: 100px;
    border-top: 2px solid #B7b6b6;
    padding-top: 6px
}
.first-box-mobile-rent{
    height: 290px;
    width: 100%;
    box-sizing: border-box;
}
@media only screen and (min-width: 1023px) and (max-width: 1261px){
   .rent-form{
    grid-column: 2/5;
   }
   .rent-title h2{
    font-size: 20px;
   }
   .rent-block{
    grid-column: 2/6;
   }
   .rent-backgound{
    height: 110vh;
   }

}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    
    .rent-form{
        grid-column: 2/6;
       }
    .rent-block{
        grid-column: 2/6;
        padding-top: 100px;
       }
       .rent-title h2{
        font-size: 18px;
       }
       .rent-backgound{
        height: 70vh;
       }
} 
@media only screen and (min-width: 330px) and (max-width: 768px){
    .rent-label{
        font-size: .9em;
    }
    .rent-radio-title{
        font-size: .9em;
        margin-bottom: 0;
    }
    .rent-result-box{
        margin-top: 46px;
    }
    .rent-send{
        text-align: center;
    }
    .legal-bond{
        margin: 60px 0;
    }
}