.directors-backgound{
    background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 80.4%),  url(../../../public/img/CotizadorDirectores.jpg);
    background-size: cover ;
    height: 110vh;
    width: 100vw;
}
.dark-block{
    background-color: #1e0606;
    height: 0px;
}
.director-hero{
    width: 100vw;
    box-sizing: border-box;
    position: absolute;
    margin-top: 3%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 120px auto;
    grid-gap: 30px;
    padding: 20px 40px 0px 40px; 
    height: 100%;
}
.director-block{
    grid-column: 2/5;
    padding-top: 10%;
}
.item-directors-selected{
    margin: 0;
    font-family: 'Sora', sans-serif;
    font-size: 15px;
    color: #FFF;
    font-weight: 800;
    padding-right: 8px;
    margin-right: 8px;
}
.item-directors{
    border-right: 1.5px solid #D8CECE;
    margin: 0;
    font-family: 'Sora', sans-serif;
    font-size: 15px;
    color: #FFF;
    padding-right: 8px;
    margin-right: 8px;
    font-weight: 400;
}
.director-form{
    grid-column: 2/7
}
.form{
    display: flex;
    justify-content: space-between;
}
.first-form{
    width: 40%;
}
.first-section{
    width: 45%;
   margin-right: 5%;
}
.question-label{
    display: block;
    color: #FFF;
    font-family: 'Sora', sans-serif;
    font-size: 13px;
    font-weight: 600;
    text-align: right;
    width: 100%;
    margin-top: 4px;
    padding-bottom: 10px;
}
.bill-form{
    width: 45%;
}
.director-result-box{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}
.display-none{
    display: none;
}
.director-subtitle-form{
    font-weight: 600;
}
.second-director-subtitle-form{
    margin-top: 16px;
    font-weight: 600;
}
.amount-result{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 36px;
    padding-left: 15px;
    background-color: rgba(255, 255, 255, 0.204);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 1.7em;
    color: #fff;

}
.button-send{
   text-align: right ;
}
.button-container{
    grid-column: 2/4;
    margin-top: 15%;
}
.explain{
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
}
.option{
    color:#1e0606;
}
.thanks-message-mobile{
    color: #FFF;
}
.legal-bond-directors{
    font-size: 12px;
    color: #FFF;
    margin-top: 100px;
    border-top: 2px solid #B7b6b6;
    padding-top: 6px
}
 
 
 @media only screen and (min-width: 768px) and (max-width: 1023px){
    .bill-form{
        width: 100%;
    }
    .directors-backgound{
        height: 100vh;
    }
    .director-block{
        grid-column: 2/7;
        margin-top: 10%;
    }
    .director-form{
        margin-top: 10%;
        grid-column: 2/7;
    }
    .first-form{
        width: 100%;
    }
    .form{
        display: block;
    }
    .first-section{
        width: 100%;
    }
 } 
 @media only screen and (min-width: 330px) and (max-width: 768px){
   
    .bill-first-form{
        background-color:#9b2743;
        box-sizing: border-box;
        padding-top: 12px;
    }
    .first-form{
        width: 100%;
    }
    .question-label{
        margin-bottom: 16px;
    }
    .button-box{
        margin-top: 30px;
        text-align: center;

    }
    .director-subtitle{
        font-size: 1em;
        font-weight: 600;
        color: #FFF;
        margin-bottom: 12px
    }
    .director-subtitle-form
    .second-director-subtitle-form{
        font-size: 1.1em;
    }
    .bill-form{
      grid-row: 3;
      grid-column: 1;
    }
    
    .bordeax-background-director{
        background-color: #9b2743;
        padding: 30px 20px 20px 20px;
        display:grid;
        grid-template-columns:repeat(1, 1fr);
    }
    .form{
        display: block;
    } 
 }