.press-background{
    min-height: 97%;
    margin-bottom: -370px;
}
.press-box{
    padding-bottom: 48px;
    margin: 0 40px ;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 290px;
    column-gap: 30px;
    row-gap: 24px;
    align-items: flex-start;
}
.press-box-detail{
    padding-bottom: 48px;
    margin: 0 40px ;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 30px;
    align-items: flex-start;
}

.push {
    height: 366px;
  }
.main-note-img{
    grid-column: 1/4;

}
.main-note-img img{
    grid-row: 1/3;
    width: 100%;
    height: 270px;
    object-fit: cover;
    
}
.press-note-box{
    grid-column: 4/8;
    
}
.head-note{
    margin-bottom: 16px;
    color: #B7B6B6;
    font-size: 15px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #B7B6B6;
    padding-bottom: 4px;
}
.head-date{
    font-weight: 400;
}
.main-note-content{
    grid-column: 4/8;
    text-transform: uppercase;  
}
.box{
    display: flex;
    flex-direction: column;
}
.box-text{
    max-height: 180px;
    margin-top: 10px; 
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    color: #1e0606;
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    line-height: 20px; 
   columns: 2;
}
.box-text-detail{
    margin-top: 10px; 
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    color: #1e0606;
    columns: 2;
}
.box-text-detail p{
    font-family: 'Sora', sans-serif;
    font-size: 14px;
    line-height: 20px; 
    color: #1e0606;
    margin-bottom: 8px;

}
.more{
    box-sizing: border-box;  
    margin-top: 6 px;
    text-align: center;
    color: #fff;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
}
.see{
    font-weight: 500;
    box-sizing: border-box;
    background-color: #B7B6B6;
    padding-top: 5px;
    width: 100px;
    height: 30px;
    margin-right: 2px;
    color: #fff;
}
.see-icon{
    box-sizing: border-box;
    background-color: #B7B6B6;
    width: 30px;
    height: 30px;
    
}
.menu-note{
    border-bottom: 2px solid #9b2743;
    padding-bottom: 5px;
    margin: 5px 0;
    color:#1e0606;
}
.menu-note-detail{
    border-bottom: 2px solid #9b2743;
    padding-bottom: 5px;
    margin: 15px 0 5px 0;
    color:#1e0606;
    width: 30%;

}
.menu-note-detail a{
    color:#1e0606;
}
.menu-note a.visited{
    color:#1e0606;
}
.press-menu-link{
    color:#B7B6B6;
}
.card-note{
    margin-top: 6px;
    max-height: 430px;
    display: flex;
    flex-direction: column;
    
    
}
.card-note-content{
    margin-top: 5px;
    text-transform: uppercase;  
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 384px;

}
.card-text{
    text-transform: none;
    color: #1e0606;
    margin-top: 5px;

}
.card-icon{
    float: right;
    width: 20px;
    height: 20px;
    background-color: #B7B6B6;
    color: #fff;
    margin-top: -20px;

}
.card-note-img{
    height: 250px;
    width: 100%;
    object-fit:cover;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.second-grid{
    grid-column: 2/8;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    column-gap: 30px;
    row-gap: 16px;
    
}

@media only screen and (min-width: 1440px){
    

}
@media only screen and (min-width: 1023px) and (max-width: 1261px){
    .second-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .box{
        column-count: 1;
       
    }
    .box-text{
        max-height: 150px;
        font-size: 12px;
        line-height: 18px; 

    
    }
    .more{
        margin-top: 20px;
    }

}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .second-grid{
        grid-template-columns: repeat(2, 1fr);
    }
    .box{
        column-count: 1;
       
    }
    .box-text{
        max-height: 150px;
        font-size: 11px;
        line-height: 16px; 

    
    }
    .more{
        margin-top: 20px;
    }
   
}