.us-backgound{
    background: linear-gradient(105.33deg, rgba(0, 0, 0, 0.65) 10.83%, rgba(0, 0, 0, 0) 81.77%), url(../../../public/img/Empresa1.png);
    background-size: cover ;
    min-height: 740px;
    width: 100vw;
}

.grid{
    position: absolute;
    margin-top: 30%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 30px;
    padding: 20px 40px 0 40px;

}
.us-block{ 
    grid-column: 2/5;
    
}
.staffBackgound{
    background-color: #1E0606;
   
}
.staffHero{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 30px;
    padding: 60px 40px 0 40px;
    grid-column-start: 2;

}
.staffBlock{
    grid-column: 2/5;

}

.video-grid{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 30px;
    padding: 20px 40px 0 40px;
    grid-template-rows: auto;
}
.card-box{ 
    grid-column: 2/6;  
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 25px;
    width: 100%;
    height: 100%;
}
.group-photo{
    width: 100vw;
    margin: 60px 0;
    
}
.work-button{
    display: flex;
    padding: 0px 0 60px 0;
    justify-content: center;
}
.re-backgound{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 10.85%, rgba(0, 0, 0, 0) 67.26%), url(../../../public/img/ofiRetocada.jpg);
    background-size: cover;
    min-height: 740px;
    width: 100vw;
}
.re-block{
    grid-column: 2/5;

}
.team-block{
    grid-column: 2/5;
}
.re-grid{
    position: absolute;
    margin-top: 2%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 30px;
    padding: 20px 40px 0 40px;

}
.com-backgound{
    background: linear-gradient(-180deg, rgba(0, 0, 0, 0.7) 10.85%, rgba(0, 0, 0, 0) 67.26%), url(../../../public/img/Patio.jpg);
    background-size: cover ;
    min-height: 740px;
    width: 100vw;
}
.compromise-grid{
    position: absolute;
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 30px;
    padding: 20px 40px 0 40px;

}

.fundations-box{
    padding: 24px 15%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media only screen and (min-width: 1023px) and (max-width: 1261px){
  
    .us-block{ 
        grid-column: 2/6;
        
    }
    .staffBlock{ 
        grid-column: 2/6;
        
    }
    .re-block{
        grid-column: 2/6;
        }
    .team-block{
            grid-column: 2/6;
        }

}
@media only screen and (min-width: 768px) and (max-width: 1023px){
    .us-backgound{
        min-height: 600px;
    }
    .re-backgound{
        min-height: 550px;
    }
    .com-backgound{
        min-height: 550px;
    }
    .us-block{ 
        grid-column: 1/6;
        padding-top: 22%;
        
    }
    .staffBlock{
        grid-column: 1/6;

    }
    .card-box{ 
        grid-column: 1/8;  
    }
  
   .re-block{
    grid-column: 1/6;
    }
    .team-block{
        grid-column: 1/6;
    }
    .compromise-grid{
        margin-top: 5%;
    }
    .fundations-box{
        padding: 16px 10%;

    }
}
@media only screen and (min-width: 340px) and (max-width: 768px){
.top-section{
    height: 290px;
}
.image-us-mobile{
    width: 100%;
    
}
.text-box-mobile{
    padding: 20px;
    background-color: #1E0606;
    color: #fff;
    margin-top: -5px;
}
.subtitle-us-mobile{
    font-size: 14px;
    margin-bottom: 10px;
}
.text-us-mobile{
    font-size: 17px;
    margin-bottom: 10px;
}
.center-button{
    text-align: center;
    margin: 10px 0;
}
.carrousel-box{
    width: 100vw;
    height: 80vh;
    padding-top: 70px;
    object-fit: cover;
}
.swiper{
    height: 100%;
    width: 100%;
}
.video-size{
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.fundations{
    width: 80%;
}
.fundations-bigger{
    padding-right: 20px;
}
.fundations-box{
    justify-content: center;
    padding: 16px 20px;
}
.card-title-mobile{
    width: 90%;
    background-color: rgba(155, 39, 67, 0.8);
    border-radius: 16px;
    margin: -40% 5% 20%;
    position: relative;
    text-align: center;
    padding: 2px;
}

}