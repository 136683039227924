.light-input{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 2.5em;
    padding-left: 5px;
    background-color: rgba(255, 255, 255, .4);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: .8em;
    color: #1e0606;
}
.input-box{
    height: 3em;
    margin-bottom: 14px;
}
.input-box-content{
    height: 7.5em;
}
.error{
    color: #9b2743;
    font-size: 9px;
    line-height: 15px;
    font-weight: 500;
}
.error-white{
    color: #fcf45d;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
   /*  background-color: #9B2743; */
    padding-left: 3px;
}
.light-input:valid{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 2.5em;
    padding-left: 5px;
    background-color: rgba(255, 255, 255, .4);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: .8em;
    color: #1e0606;
}
.light-input-client{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 2.5em;
    padding-left: 5px;
    background-color: rgba(255, 255, 255, 0.204);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: .8em;
    color: #fff;
}
.light-input-client::placeholder{
    font-weight: 400;
    font-size: .8em;
    color: #B7b6b6;

}

.white-input{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #fff;
    width: 100%;
    height: 2.5em;
    padding-left: 5px;
    background-color: rgba(255, 255, 355, .6);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: .8em;
    color: #1e0606;
}
.white-area{
    box-sizing: border-box;
    height: 100px;
    width: 100%;
    font-family: 'Sora', sans-serif;
    border-bottom: solid 2px #fff;
    background-color: rgba(255, 255, 355, .6);
    font-weight: 600;
    font-size: .8em;
    color: #1e0606;
    margin-bottom: -2px;
}
input:focus{
    outline: none;
    border-color: #9B2743;
}
textarea{
    outline: none;
    border: none;

}
.dark-input{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 2.5em;
    padding-left: 5px;
    background-color: rgba(30, 6, 6, .6);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: .8em;
    color: #fff;
    margin-top: 2px;
}
.file-white{
    font-family: 'Sora', sans-serif;
    color: #fff;
    margin: 2px 0 0px 0;

}
.dark-area{
    box-sizing: border-box;
    height: 100px;
    width: 100%;
    font-family: 'Sora', sans-serif;
    border-bottom: solid 2px #B7b6b6;
    background-color: rgba(30, 6, 6, .6);
    font-weight: 600;
    font-size: .8em;
    color: #fff;
    margin-top: 2px;
}
.radio{
    height: 2.5em;
    width: 2.5em;
    accent-color:#9B2743;
    margin: 0 12px 0 0;
  
}

.light-input-rent{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 36px;
    padding-left: 36px;
    background-color: rgba(255, 255, 255, .4);
    font-family: 'Sora', sans-serif;
    font-weight: 700;
    font-size: 1.8em;
    color: #fff;
}
.light-input-director{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 36px;
    padding-left: 36px;
    background-color: rgba(255, 255, 255, 0.204);
    font-family: 'Sora', sans-serif;
    font-weight: 700;
    font-size: 1.7em;
    color: #fff;
}
.light-input-file{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 2.5em;
    padding: 5px 5px 0 0;
    background-color: rgba(255, 255, 255, .3);
    font-family: 'Sora', sans-serif;
    font-weight: 400;
    font-size: .8em;
    color: #FFF;
}

input[type=file]::file-selector-button {
    background-color: transparent;
    font-family: 'Sora', sans-serif;
    font-weight: 400;
    border-radius: 10px;
    border: 1px solid #fff;
    margin-left: 1px;
    cursor: pointer;
    color: #fff;

}

.rent-user-input{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 30px;
    padding-left: 7px;
    background-color: rgba(255, 255, 255, 0.262);
    font-family: 'Sora', sans-serif;
    font-weight: 700;
    font-size: .8em;
    color: #fff;

}
.rent-file-input{
    box-sizing: border-box;
    border: 0;
    width: 82%;
    height: 30px;
    padding-top: 7px;
    background-color: rgba(255, 255, 255, 0.2);
    font-family: 'Sora', sans-serif;
    font-weight: 700;
    font-size: .7em;
    color: #fff;
}
.rent-notification{
    font-size: .7em;
    font-weight: 400;
    color: #fff;
    margin-top: 4px;
}
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
.check{
    height: 2.3em;
    width: 2.3em;
    accent-color:#9B2743;
    /* border-color: #9B2743; */
    margin-right: 20px;
    
  }
  .label-white{
    color: #fff;
  }
  .label-white-long{
    line-height: 1cap;
    font-size: .7em;
    color: #FFF;
    font-weight: 600
  }
  .label-black{
    color:#1e0606;
  }

  ::placeholder {
    font-weight: 400;
    font-size: .8em;
    
  }
  .label-white::placeholder{
    color: #fff;
   
  }
  .rent-user-input::placeholder{
    color: #FFF;
  }
  .select-box{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 36px;
    padding-left: 36px;
    background-color: rgba(255, 255, 255, 0.204);
    font-family: 'Sora', sans-serif;
    font-weight: 600;
    font-size: 1.3em;
    color: #fff;
    outline: none;
}
.select-box-school{
    box-sizing: border-box;
    border: 0;
    border-bottom: solid 2px #B7b6b6;
    width: 100%;
    height: 2em;
    background-color: rgba(255, 255, 255, .4);
    font-family: 'Sora', sans-serif;
    font-weight: 500;
    font-size: .8em;
    color: #1e0606;
    outline: none;
    margin-top: 4px;
    padding-left: 2px;
}



  @media only screen and (min-width: 330px) and (max-width: 768px){
    .label-black{
        font-size: .8em;
        
    }
    .light-input{
        font-size: .8em;

    }
    .label-white{
        font-size: .8em;
        color: #FFF;
    }
    .white-input{
        background-color: rgba(255, 255, 355, .4);
        color: #FFF;
    }
    .white-area{
        background-color: rgba(255, 255, 355, .4);
        color: #FFF;
    }
    .light-input::placeholder {
        font-size: 1em;
        color: #575353;
        
      }
      .white-area::placeholder{
        color: #e6dddd;
        font-size: 1.2em;
      }
      .white-input::placeholder{
        color: #e6dddd;
        font-size: 1.2em;

      }
      .radio{
        height: 2.5em;
        width: 2.5em;
        accent-color:#d27c90;
        /* border-color: #9B2743; */
        margin-right: 30px;
    }
    .light-input-rent{
        font-size: 1.7em;
       
    }
    .label-white{
        font-size: .9em;
    }
    .rent-user-input{
        font-size: .8em;
    }
    .rent-user-input::placeholder{
        color: #e6dddd;
        font-size: 1.2em;

    }
    .light-input-file{
        padding: 6px 0 0 0;
    }
    .light-input-client::placeholder{
        color: #e6dddd;
        font-size: 1.2em;

    }
}
